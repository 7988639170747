import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import Title from "../../layout/title"
import { personsSelector } from "../../../store/persons/selectors"
import Admin from "./components/admin"
import CreateAdminModal from "./components/createAdminModal"
import Loader from "../../base/loaders/loader"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import useUser from "../../../utils/hooks/useUser"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { fetchPersons } from "../../../store/persons/actions"
import Button from "../../base/buttons/regular"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const messages = defineMessages({
  adminsTitle: "Admins",
})

const Admins = () => {
  const [editIdx, setEditIdx] = useState()
  const [companyUUID, setCompanyUUID] = useState()
  const [inCreate, setInCreate] = useState(false)

  const dispatch = useDispatch()
  const user = useUser()
  const formatMessage = useFormatMessage()

  useEffect(() => {
    const id = _.get(user, "company.uuid")
    if (id) {
      setCompanyUUID(id)
      dispatch(fetchPersons(id))
    }
  }, [user])

  /* get all persons from state */
  const { results: management, loading } = useSelector(personsSelector(companyUUID))
  const { regular } = useThemeColors()

  const users = (management ?? []).filter(user => user.isetolo)

  return (
    <div className="flex flex-col ">
      <div className="w-full bg-white shadow sm:rounded">
        <div className={`h-1  bg-${regular} sm:rounded-t`} />
        <div className="p-8 sm:px-8">
          <Title>{formatMessage(messages.adminsTitle)}</Title>

          <div className="py-1">
            {users &&
              users.map((admin, i) => (
                <Admin
                  admin={admin}
                  personUUID={admin.uuid}
                  key={admin.uuid}
                  inEdit={editIdx === i}
                  setInEdit={() => setEditIdx(i)}
                  companyUUID={companyUUID}
                />
              ))}
            {!users && loading && (
              <div className="w-full flex items-center justify-center">
                <Loader name="ball-spin-fade-loader" />
              </div>
            )}
            <CreateAdminModal visible={inCreate} onClose={() => setInCreate(false)} companyUUID={companyUUID} />
          </div>

          <div className="text-center text-xs mt-10 text-gray-700">Copyright &copy; Atolo 2022</div>
        </div>
      </div>
      <div className="flex justify-end items-center mt-4">
        <Button primary onClick={() => setInCreate(true)}>
          Create new admin
        </Button>
      </div>
    </div>
  )
}

export default Admins
