import { getHRManagerList } from "src/api"
import { onLogout } from "../account/actions"

export const setHRManagers = (companyUUID, hrManagers) => ({
  type: "hrManagers/SET_PERSONS",
  companyUUID,
  payload: hrManagers,
})

export const setLoading = (companyUUID, loading) => ({
  type: "hrManagers/SET_LOADING",
  companyUUID,
  payload: loading,
})

export const setError = (companyUUID, error) => ({
  type: "hrManagers/SET_ERROR",
  companyUUID,
  payload: error,
})

export const fetchHRManagers = companyUUID => async dispatch => {
  dispatch(setLoading(companyUUID, true))
  try {
    const persons = await getHRManagerList(companyUUID)
    dispatch(setHRManagers(companyUUID, persons))
  } catch (error) {
    dispatch(setError(companyUUID, error))
    if ((error.code = "ForbiddenAccess" && error.message === "No valid authentication")) {
      dispatch(onLogout())
    }
  } finally {
    dispatch(setLoading(companyUUID, false))
  }
}
