import React, { useEffect, useState } from "react"
import Title from "../../../layout/title"
import TextInput from "../../../base/forms/textInput"
import Label from "../../../base/forms/label"
import Button from "../../../base/buttons/regular"
import ModalContainer from "../../../base/modals/modalContainer"
import Loader from "../../../base/loaders/loader"
import usePatchPerson from "../../../../utils/hooks/usePatchPerson"
import notifications from "../../../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"

const messages = defineMessages({
  updateAdminSuccessNotificationTitle: "Admin updated!",
  updateAdminSuccessNotificationMessage: "You have successfully updated this person.",
  updateAdminErrorNotificationTitle: "Oops! Something went wrong.",
  updateAdminTitle: "Edit {firstname}'s info",
  updateAdminFirstNameInputLabel: "First name: ",
  updateAdminLastNameInputLabel: "Last name: ",
  updateAdminEmailInputLabel: "Email: ",
  updateAdminFunctionInputLabel: "Function: ",
  updateAdminConfirmButton: "Confirm",
})
const EditAdminModal = ({ onClose, person, className, withPageReload = false }) => {
  const [profile, setProfile] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setProfile(person)
    setLoading(false)
  }, [])

  const formatMessage = useFormatMessage()
  const handleChange = e => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    })
  }

  const { patchPerson, loading: updateLoading } = usePatchPerson()

  /* Handle update of user information */
  const handleUpdate = async () => {
    try {
      await patchPerson(profile)
      notifications.success(
        formatMessage(messages.updateAdminSuccessNotificationTitle),
        formatMessage(messages.updateAdminSuccessNotificationMessage)
      )
      onClose()
      if (withPageReload) {
        window?.location?.reload()
      }
    } catch (error) {
      notifications.error(formatMessage(messages.updateAdminErrorNotificationTitle), error.message, error.details)
    }
  }

  return (
    <ModalContainer nestedModal onClose={onClose} className={`bg-white w-96 shadow rounded items-center z-50 ${className}`}>
      <div className="flex flex-col w-full px-12 pb-5">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Title>{formatMessage(messages.updateAdminTitle, { firstname: person.firstname })}</Title>
            <Label className="mb-4" name={formatMessage(messages.updateAdminFirstNameInputLabel)}>
              <TextInput placeholder={person.firstname} onChange={handleChange} name="firstname" value={profile.firstname} />
            </Label>
            <Label className="mb-4" name={formatMessage(messages.updateAdminLastNameInputLabel)}>
              <TextInput placeholder={person.lastname} onChange={handleChange} name="lastname" value={profile.lastname} />
            </Label>
            <Label className="mb-4" name={formatMessage(messages.updateAdminEmailInputLabel)}>
              <TextInput placeholder={person.email} onChange={handleChange} name="email" value={profile.email} />
            </Label>
            <Button primary className="self-center text-center w-3/4" onClick={handleUpdate} loading={updateLoading}>
              {formatMessage(messages.updateAdminConfirmButton)}
            </Button>
          </>
        )}
      </div>
    </ModalContainer>
  )
}

export default EditAdminModal
