import React, { useState } from "react"
import ModalContainer from "../../../base/modals/modalContainer"
import useCreatePerson from "../../../../utils/hooks/useCreatePerson"
import { v4 as uuidv4 } from "uuid"
import TextInput from "../../../base/forms/textInput"
import notifications from "../../../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import Button from "../../../base/buttons/regular"
import Label from "../../../base/forms/label"
import Title from "../../../layout/title"

const messages = defineMessages({
  createAdminSuccessNotificationTitle: "You have created a new ATOLO admin",
  createAdminSuccessNotificationMessage: "The new admin will receive an email to set his password.",
  createAdminErrorNotificationTitle: "Oops! Something went wrong",
  createAdminButtonTooltip: "Add admin",
  createAdminFirstNameLabel: "First name",
  createAdminLastNameLabel: "Last name",
  createAdminEmailLabel: "Email",
  createAdminTitle: "New Admin",
})

const CreateAdminModal = ({ companyUUID, visible, onClose }) => {
  const [person, setPerson] = useState({
    fullname: "",
    email: "",
  })
  const { loading, createPerson } = useCreatePerson()
  const formatMessage = useFormatMessage()
  const onChange = e => {
    setPerson({ ...person, [e.target.name]: e.target.value })
  }
  const handler = async () => {
    const personUUID = uuidv4()
    try {
      await createPerson(
        {
          uuid: personUUID,
          firstname: person.firstname,
          lastname: person.lastname,
          commonname: `${person.firstname} ${person.lastname}`,
          email: person.email,
          isevaluatedperson: false,
          ishrmanager: true,
          companyuuid: companyUUID,
          isetolo: false,
          commlangcode: "fr",
        },
        companyUUID
      )
      onClose()
      setPerson({
        firstname: "",
        lastname: "",
        email: "",
      })
      notifications.success(
        formatMessage(messages.createAdminSuccessNotificationTitle),
        formatMessage(messages.createAdminSuccessNotificationMessage)
      )
    } catch (err) {
      notifications.error(formatMessage(messages.createAdminErrorNotificationTitle), err.message, err.details)
    }
  }
  return (
    <ModalContainer className="w-96" visible={visible} onClose={onClose}>
      <div className="flex flex-col px-10 pb-8">
        <Title>{formatMessage(messages.createAdminTitle)}</Title>
        <Label className="mb-4" name={formatMessage(messages.createAdminFirstNameLabel)}>
          <TextInput name="firstname" value={person.firstname} onChange={onChange} placeholder="John" className="ml-2" />
        </Label>
        <Label className="mb-4" name={formatMessage(messages.createAdminLastNameLabel)}>
          <TextInput name="lastname" value={person.lastname} onChange={onChange} placeholder="Doe" className="ml-2" />
        </Label>
        <Label className="mb-4" name={formatMessage(messages.createAdminEmailLabel)}>
          <TextInput name="email" value={person.email} onChange={onChange} placeholder="new@az.be" className="ml-2" />
        </Label>
        <Button primary className="mt-4" onClick={handler} loading={loading}>
          {formatMessage(messages.createAdminButtonTooltip)}
        </Button>
      </div>
    </ModalContainer>
  )
}

export default CreateAdminModal
