import React from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby-plugin-intl"
import { isAtoloAdmin } from "../../store/account/selectors"

const AtoloLoginGuard = ({ children }) => {
  const loggedIn = useSelector(isAtoloAdmin)
  if (!loggedIn) {
    navigate("/atolo/login")
    return null
  }
  return <div>{children}</div>
}

export default AtoloLoginGuard
