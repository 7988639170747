import React from "react"
import Admins from "../../components/atolo/admins"
import Layout from "../../components/layout"
import AtoloLoginGuard from "../../components/atolo/AtoloLoginGuard"
import { Helmet } from "react-helmet"

// TODO: Here we need t fetch the persons who have the etolo company ID as CompanyUUID, for this we need the etolo company ID
const AdminsPage = () => {
  return (
    <AtoloLoginGuard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Admins</title>
      </Helmet>
      <Layout type="atolo">
        <div className="h-full w-full flex-1 max-w-4xl w-4/5 md:pt-8 md:pb-20">
          <Admins />
        </div>
      </Layout>
    </AtoloLoginGuard>
  )
}

export default AdminsPage
