import React, { useState } from "react"
import ConfirmModal from "../../../base/modals/confirmModal"
import useDeletePerson from "../../../../utils/hooks/useDeletePerson"
import ReactTooltip from "react-tooltip"
import notifications from "../../../../utils/notifications"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import { defineMessages } from "gatsby-plugin-intl"
import EditAdminModal from "./editAdminModal"
import { IoMdClose } from "react-icons/io"

const messages = defineMessages({
  deleteAdminSuccessNotificationTitle: "Person deleted!",
  deleteAdminSuccessNotificationMessage: "You have successfully updated this person.",
  deleteAdminErrorNotificationTitle: "Oops! Something went wrong.",
  updateAdminSuccessNotificationTitle: "Person updated!",
  updateAdminSuccessNotificationMessage: "You have successfully updated this person.",
  updateAdminErrorNotificationTitle: "Oops! Something went wrong.",
  editAdminButtonTooltip: "Edit admin",
  deleteAdminButtonTooltip: "Remove admin",
  deleteAdminConfirmModalTitle: "Are you sure you want to delete this admin?",
  deleteAdminConfirmModalDeleteButton: "Delete",
})

const Admin = ({ admin, personUUID, companyUUID }) => {
  const [inDelete, setInDelete] = useState(false)
  const [editAdmin, setEditAdmin] = useState(undefined)

  const formatMessage = useFormatMessage()

  const { deletePerson } = useDeletePerson()

  const remove = async () => {
    try {
      await deletePerson(personUUID, companyUUID)
      setInDelete(false)
      notifications.success(
        formatMessage(messages.deleteAdminSuccessNotificationTitle),
        formatMessage(messages.deleteAdminSuccessNotificationMessage)
      )
    } catch (error) {
      notifications.error(formatMessage(messages.deleteAdminErrorNotificationTitle), error.message, error.details)
      setInDelete(false)
    }
  }

  return (
    <>
      <div className="flex flex-row">
        <div className="flex-1 flex flex-row py-2 items-center hover:bg-gray-100 cursor-pointer" onClick={() => setEditAdmin(admin)}>
          <div className="text-sm flex flex-1 truncate pr-4">
            {admin.firstname} {admin.lastname}
          </div>
          <div className="text-sm flex flex-1 truncate pr-4">
            <a className="text-sm text-blue-700 truncate pr-4" onClick={e => e.stopPropagation()} href={`mailto:${admin.email}`}>
              {admin.email}
            </a>
          </div>
        </div>
        <ReactTooltip id={`remove-admin-${admin.uuid}`} place="top" type="error" effect="solid">
          {formatMessage(messages.deleteAdminButtonTooltip)}
        </ReactTooltip>
        <a data-for={`remove-admin-${admin.uuid}`} data-tip>
          <div
            className="text-red-500 flex items-center cursor-pointer h-full ml-2"
            onClick={e => {
              e.stopPropagation()
              setInDelete(true)
            }}
          >
            <IoMdClose size={20} />
          </div>
        </a>
      </div>
      <ConfirmModal
        visible={inDelete}
        title={formatMessage(messages.deleteAdminConfirmModalTitle)}
        confirmText={formatMessage(messages.deleteAdminConfirmModalDeleteButton)}
        onConfirm={remove}
        onClose={() => setInDelete(false)}
      />
      {editAdmin && (
        <EditAdminModal
          onClose={() => {
            setEditAdmin(undefined)
          }}
          person={editAdmin}
        />
      )}
    </>
  )
}

export default Admin
