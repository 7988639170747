import { useState } from "react"
import { createPerson, getPersonsByEmail, getPersonList, patchPerson } from "src/api"
import { fetchPersons } from "../../store/persons/actions"
import { fetchHRManagers } from "../../store/hrManagers/actions"
import { fetchCompany } from "../../store/company/actions"
import { useDispatch } from "react-redux"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"

export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const execute = async (person, companyUUID) => {
    try {
      setLoading(true)
      setError(undefined)

      trackCustomEvent(getAPI_CALL_STARTED("CREATE_PERSON"))
      let matchingPerson = []
      try {
        const existingPersons = await getPersonsByEmail(person.email, companyUUID)
        if (existingPersons.length > 0) {
          matchingPerson = existingPersons[0]
        }
      } catch {
        // fail silently
      }

      let data
      if (matchingPerson) {
        data = await patchPerson({
          ...matchingPerson,
          ...person,
          isevaluatedperson: person.isevaluatedperson || matchingPerson.isevaluatedperson,
          isetolo: person.isetolo || matchingPerson.isetolo,
          ishrmanager: person.ishrmanager || matchingPerson.ishrmanager,
          uuid: matchingPerson.uuid,
        })
      } else {
        data = await createPerson(person)
      }

      dispatch(fetchPersons(companyUUID))
      dispatch(fetchHRManagers(companyUUID))
      dispatch(fetchCompany(companyUUID))
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, createPerson: execute }
}
